import { readState } from "@/__main__/app-state.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import { didInitConsent } from "@/feature-consent/consent.mjs";
import getSHA256Hash from "@/util/get-hash.mjs";

export default async function getPlatformId() {
  await didInitConsent;

  let hash: string | undefined;

  const h = readState[atob("dXNlcg")]?.[atob("ZW1haWw")];
  hash ||= h && (await getSHA256Hash(h));

  hash ||= (await blitzMessage(EVENTS.PLATFORM_ID)) as string;

  return hash;
}
